<template>
  <div class="min-height-300 bg-primary position-absolute w-100"></div>
  <Minisidebar />
  <main class="main-content position-relative border-radius-lg">
    <Topbar />
    <div class="container-fluid py-4">
      <div class="row mx-2 mt-4">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-3">
              <div class="row">
                <div class="col-6 d-flex align-items-center">
                  <!-- body -->
                  <h6>Toll calculator</h6>

                  <!-- end body -->
                </div>
              </div>
            </div>

            <div class="card-body ">


              <div class="row col-12">
                <div class="col-9 row ">

                  <div class="col-1">
                    <label class="control-label">Select: </label><span class="text-danger">*</span>
                  </div>
                  <div class="col-4">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="searchType" checked id="typeRoute "
                        v-model="searchType" value="route" />
                      <label class="form-check-label" for="typeRoute">
                        Route Planning
                      </label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="searchType" id="typeLocation"
                        v-model="searchType" value="verify" />
                      <label class="form-check-label" for="typeLocation">
                        Verify Location
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row col-12">
                <div class="col-9 ">
                  <Form @submit="initMap" :validation-schema="fleetschema" class="form-horizontal" role="form"
                    v-if="searchType === 'route'" id="vehicles_upload_form">
                    <div class="form-group">
                      <div class="row col-12">
                        <div class="col-1">
                          <label class="control-label">Start: </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-8">
                          <div class="input-icon">
                            <vue-google-autocomplete id="from" ref="fromAddress" classname="form-control"
                              placeholder="Start address" v-on:placechanged="getfromData" country="us">
                            </vue-google-autocomplete>
                          </div>
                        </div>
                        <div class="col-1">
                          <button type="button" id="submit_btn" v-on:click="alterStopOver('add')"
                            class="btn btn-secondary btn-outline btn-s p-1" title="Add stop location">
                            <i class="ni ni-fat-add"></i>
                            stops
                          </button>
                        </div>
                      </div>

                    </div>
                    <div class="form-group" v-for="(item, i) in stopsCordinate" :key="index">

                      <div class="row col-12">


                        <div class="col-1">
                          <label class="control-label">Stop {{ i + 1 }}: </label><span class="text-danger">*</span>

                        </div>
                        <div class="col-8">
                          <div class="input-icon">
                            <vue-google-autocomplete classname="form-control" :id="'stop' + i" :ref="'stop' + i"
                              placeholder="Stopover address" v-on:placechanged="getLegData" country="us">
                            </vue-google-autocomplete>
                          </div>
                        </div>
                        <div class="col-1">
                          <button type="button" id="submit_btn" v-on:click="alterStopOver('remove', i)"
                            class="btn btn-danger btn-outline btn-s p-1" title="Add stop location">
                            <i class="ni ni-fat-remove"></i>

                          </button>
                        </div>

                      </div>
                    </div>
                    <div class="form-group">

                      <div class="row col-12">


                        <div class="col-1">
                          <label class="control-label">Destination: </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-8">
                          <div class="input-icon">
                            <vue-google-autocomplete id="to" ref="toAddress" classname="form-control"
                              placeholder="Stop address" v-on:placechanged="getToData" country="us">
                            </vue-google-autocomplete>
                          </div>
                        </div>






                        <div class="col-1">
                          <button type="submint" id="submit_btn" class="btn btn-primary btn-outline btn-xs p-2 ml-1">
                            <i class="fa fa fa-pencil"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <Form @submit="initVerifyMap" :validation-schema="fleetschema" class="form-horizontal" role="form"
                    v-if="searchType === 'verify'" id="vehicles_upload_form">

                    <div class="form-group">

                      <div class="row col-12">


                        <div class="col-1">
                          <label class="control-label">Latitude: </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-4">
                          <div class="input-icon">
                            <input type="text" class="form-control" v-model="verifyLat" placeholder="Latitude" />
                      
                          </div>
                        </div>
                        <div class="col-1">
                          <label class="control-label">Longitude: </label><span class="text-danger">*</span>
                        </div>
                        <div class="col-4">
                          <div class="input-icon">
                         <input type="text" class="form-control" v-model="verifyLng" placeholder="Longitude" />
                          </div>
                        </div>






                        <div class="col-1">
                          <button type="submint" id="submit_btn" class="btn btn-primary btn-outline btn-xs p-2 ml-1">
                            <i class="fa fa fa-pencil"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>


                  <div id="googleMap"></div>
                </div>

                <div class="col-3" >
                  <div class="card mb-6 p-3 shadow text-center">
                    <h5 class="mb-3 fw-bold">Summary</h5>
                    <ul class="list-group text-start">
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <strong>Origin:</strong>
                        <span class="ms-auto">{{ origin }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <strong>Destination:</strong>
                        <span class="ms-auto">{{ destination }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <strong>Total Time:</strong>
                        <span class="ms-auto">{{ travel_time }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <strong>Distance:</strong>
                        <span class="ms-auto">{{ distnce }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <strong>Total Cost:</strong>
                        <span class="ms-auto">${{ toll_cost }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <a target="_blank" @click="downloadJornyDetail()">
                          <i class="ni ni-cloud-download-95"></i>
                          <!-- Add your desired icon class here -->
                          Download Trip Info
                        </a>
                      </li>
                    </ul>

                    <div class="row mt-3">
                      <div class="col-md-12 d-flex justify-content-start align-items-center"
                        v-for="(agency, index) in agencyData" :key="index">
                        <div class="dot col-md-2" :style="{ backgroundColor: agency.color }"></div>
                        <div class="label ml-2 col-md-7">
                          {{ agency.agency }}
                        </div>
                        <div class="label ml-2 col-md-3">
                          $ {{ agency.amount }}
                        </div>
                      </div>
                    </div>
                    <div class="p-3 text-center">
                      <h5 class="mb-3 fw-bold">More Filters</h5>
                      <div class="mb-3">
                        <label for="filterOption1" class="form-label">Filter by Agency
                        </label>
                        <select class="form-select" id="filterOption1" v-model="selectedAgency">
                          <option value="" selected>Select</option>
                          <option v-for="(option, index) in agencyData" :key="index" :value="`${option.index}`">
                            {{ option.agency }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="filterOption2" class="form-label">Filter by Class</label>
                        <select class="form-select" id="filterOption2" v-model="selectedClass">
                          <option v-for="index in 6" :key="index" :value="index">
                            {{ `Class ${index}` }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="filterOption2" class="form-label">Filter by Payment method</label>
                        <select class="form-select" id="filterOption2" v-model="selectedPaymentMethod">
                          <option v-for="(option, index) in paymentMethodData" :key="index" :value="`${option.id}`">
                            {{ option.name }}
                          </option>
                        </select>
                      </div>

                      <div class="mb-3">
                        <label for="filterOption2" class="form-label ">Router key</label>
                        <div class="d-flex justify-content-between align-items-center">
                          <div
                            style="min-width: 50px ; max-width: 50px; height: 10px; background-color: #8841e8; border-radius: 3px;">
                          </div>
                          <h6>Route 1</h6>
                          <div
                            style="min-width: 50px; max-width: 50px;height: 10px; background-color: #3f5919; border-radius: 3px">
                          </div>
                          <h6>Route 2</h6>
                          <div
                            style="min-width: 50px;max-width: 50px; height: 10px; background-color:  #e0d904; border-radius: 3px">
                          </div>
                          <h6>Route 3</h6>

                        </div>
                      </div>

                      <div class="mb-3">
                        <label for="filterOption2" class="form-label">Chose Route</label>
                        <select class="form-select" id="filterOption2" v-model="selectedRoutes">
                          <option v-for="(option, index) in RoutesData" :key="index" :value="`${option.id}`">
                            {{ option.name }}
                          </option>
                        </select>
                      </div>


                      <div class="mb-3">
                        <label for="filterOption3" class="form-label">Time of Travel</label>
                        <input type="datetime-local" class="form-control" placeholder="MM/DD/YYYY" v-model="travelTime"
                          id="filterOption3" />
                      </div>

                      <div class="mb-3 row">
                        <label class="form-label col-12">Mode of Transport</label>
                        <div class="col-6">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="transportMode" checked
                              id="drivingOption " v-model="travelMode" value="DRIVING" />
                            <label class="form-check-label" for="drivingOption">
                              Driving
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="transportMode" id="transitOption"
                              v-model="travelMode" value="TRANSIT" />
                            <label class="form-check-label" for="transitOption">
                              Transit
                            </label>
                          </div>
                        </div>
                      </div>
                      <br />
                      <button type="submit" class="btn btn-primary" @click="processFilters">
                        Submit <i class="bi bi-check-circle"></i>
                      </button>


                    </div>
                  </div>
                </div>
              </div>

              <!-- replenish modal -->

              <div class="card">
                <div class="modal fade" id="filterTransponderModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          HAVE TRANSPONDER OR ACCOUNT?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <Form @submit="processTransponderFilters" role="form">
                        <div class="modal-body">
                          <div class="row">
                            <h6 class="text-uppercase text-primary text-xs opacity-7">
                              Please choose the agencies for which you have a
                              transponder or an account.
                            </h6>
                          </div>

                          <hr />

                          <div class="row mt-3">
                            <div v-for="agency in selectedAgencies" class="col-md-6">
                              <div class="row align-items-center">
                                <div class="col-md-9">
                                  <p>{{ agency.agency }}</p>
                                </div>
                                <div class="col-md-3 text-right">
                                  <i class="ni ni-fat-remove text-warning text-sm opacity-10"
                                    @click="removeSelectedAgency(agency)"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="row">
                              <div class="col-md-6">
                                <label for="sel_agency" class="col-form-label">
                                  Selected Agency:</label><br />
                                <!-- <Field name="sel_card" type="text" class="form-control text-capitalize" placeholder="Selected Agency"
                      disabled v-model="card_number" /> -->

                                <select class="form-select" id="filterOption2" @change="addToSelected">
                                  <option value="" disabled selected>
                                    Select Agency
                                  </option>
                                  <option v-for="(option, index) in agencyData" :key="index" :value="`${index}`">
                                    {{ option.agency }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                            Close
                          </button>
                          <button type="Submit" class="btn btn-primary" data-bs-dismiss="modal">
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>

              <!-- replenish modal -->
            </div>
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <footer class="footer pt-3">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div class="copyright text-center text-sm text-muted text-lg-start">
                Powered by
                <a href="https://www.innovativetoll.com" class="font-weight-bold" target="_blank">Innovativetoll
                  Solutions</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import Minisidebar from "@/components/partials/Sidebar.vue";
import Topbar from "@/components/partials/Topbar.vue";
import Footer from "@/components/partials/Footer.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@/assets/css/dataTables.bootstrap4.min.css";
import "@/assets/css/buttons.dataTables.min.css";

import { Form, Field, ErrorMessage } from "vee-validate";
import apirequest from "@/services/opticostApi.js";
import Swal from 'sweetalert2';
export default {
  name: "signups",
  components: {
    Minisidebar,
    VueGoogleAutocomplete,
    Topbar,
    Footer,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      journey_details_blob: null,
      searchType: "route",
      title: "",
      paymentMethodData: [],
      RoutesData: [{ name: "All", id: -1 }],
      selectedRoutes: -1,
      map: null,
      directionsService: null,
      directionsRenderer: null,
      directionsRenderer2: null,
      directionsRenderer3: null,
      selectedPaymentMethod: 3,
      selectedAgency: "",
      selectedClass: 1,
      colorPalette: ["#3498db", "#e74c3c", "#2ecc71", "#f39c12"],
      axlesData: [],
      agencyData: [],
      fromlat: "",
      travelTime: this.getCurrentDateTimeString(),
      fromlng: "",
      from: "",
      tolat: "",
      tolng: "",
      to: "",
      distnce: "0",
      travel_time: "0",
      toll_cost: "0",
      origin: "-",
      destination: "-",
      travelMode: "DRIVING",
      selectedAgencies: [],

      stopsCordinate: [],
      verifyLng: "",
      verifyLat: "",

    };
  },
  methods: {
    initVerifyMap(){
          // Initialize the map
          let map = new google.maps.Map(document.getElementById("googleMap"), {
        center: {  lat:parseFloat( this.verifyLat), lng: parseFloat(this.verifyLng ) }, // Set your desired center coordinates
        zoom: 12,
      });

      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(map);

      const startMarker = this.createMarker(
        map,
        { lat:parseFloat( this.verifyLat), lng: parseFloat(this.verifyLng )},
        "Search Location",
        "green",
        {
          title: "Search Location",
          body:  `Lat: ${this.verifyLat}, Lng: ${this.verifyLng}`,
        }
      );
      

 
      const queryParams = new URLSearchParams({
        lat: this.verifyLat,
        lng: this.verifyLng,
      }); 
      apirequest(
        "POST",
        `/get_locations_within_radius?${queryParams}`,
        body
      ).then(async (response) => {
 
        const markes_response = response;
   
        for (let i = 0; i < markes_response.length; i++) {
          const marker = this.createMarker(
            map,
            markes_response[i].coordinate,
            markes_response[i].name,
            "red",
            {
              title: markes_response[i].agency_name ,
              body: `${markes_response[i].name} lat: ${markes_response[i].coordinate.lat}  Lng: ${markes_response[i].coordinate.lng}`,
            }
          );
        }
      
      }).catch((error) => {
        console.log(error);
      });

    },

    alterStopOver(action, index = null) {
      if (action === "add") {


        this.stopsCordinate.push({ lat: "", lng: "" });

      }
      if (this.stopsCordinate.length > 0 && action === "remove") {


        this.stopsCordinate.splice(index, 1);


      }
    },

    getfromData: function (addressData, placeResultData, id) {
      this.fromlat = placeResultData.geometry.location.lat();
      this.fromlng = placeResultData.geometry.location.lng();
      this.from = placeResultData.formatted_address;
      this.RoutesData = [{ name: "All", id: -1 }];
    },

    getToData: function (addressData, placeResultData, id) {
      this.tolat = placeResultData.geometry.location.lat();
      this.tolng = placeResultData.geometry.location.lng();
      this.to = placeResultData.formatted_address;

      this.RoutesData = [{ name: "All", id: -1 }];
    },

    getLegData: function (addressData, placeResultData, id) {


      const lat = placeResultData.geometry.location.lat();
      const lng = placeResultData.geometry.location.lng();

      this.stopsCordinate.push({ lat: lat, lng: lng });
      //delete * that { lat: "", lng: "" }
      this.stopsCordinate = this.stopsCordinate.filter((item) => item.lat !== "");
      this.RoutesData = [{ name: "All", id: -1 }];
    },


    showTransponderFiltersModel() {
      var modal = document.getElementById("filterTransponderModal");

      // Show the modal
      var modalBootstrap = new bootstrap.Modal(modal);
      modalBootstrap.show();
    },

    addToSelected(event) {
      const selectedValue = event.target.value;
      console.log(selectedValue);
      const selectedAgency = this.agencyData[selectedValue];
      this.selectedAgencies.push({
        agency: selectedAgency.agency,
        agency_id: selectedAgency.agency_id,
      });
    },

    removeSelectedAgency(agency) {
      this.selectedAgencies = this.selectedAgencies.filter(
        (selectedAgency) => selectedAgency.agency_id !== agency.agency_id
      );
    },
    processTransponderFilters() {
      if (this.selectedAgencies.length > 0) {
        this.processFilters()
      }
      else {
        Swal.fire("Error", "Please select at least one agency", "error");
      }
    },

    getCurrentDateTimeString() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Format: YYYY-MM-DDTHH:MM
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    async initMap() {
      if (this.fromlat === "" || this.fromlng === "" || this.tolat === "" || this.tolng === "") {
        Swal.fire("Error", "Please select start and end locations", "error");
        return;
      }
      // Initialize the map
      let map = new google.maps.Map(document.getElementById("googleMap"), {
        center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
        zoom: 12,
      });

      const trafficLayer = new google.maps.TrafficLayer();
      trafficLayer.setMap(map);
      // Initialize DirectionsService and DirectionsRenderer
      this.directionsService = new google.maps.DirectionsService();

      this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#8841e8",
          strokeWeight: 4,

        },
      });
      this.directionsRenderer2 = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#3f5919",
          strokeWeigh: 4,
        },
      });

      this.directionsRenderer3 = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#e0d904",
          strokeWeight: 4,
        },
      });



      // // Bind the directions renderers to the map
      // this.directionsRenderer.setMap(this.map);
      // this.directionsRenderer2.setMap(this.map);


      // Create custom markers
      const f_lat = parseFloat(this.fromlat);
      const f_lng = parseFloat(this.fromlng);

      const t_lat = parseFloat(this.tolat);
      const t_lng = parseFloat(this.tolng);



      const startMarker = this.createMarker(
        map,
        { lat: f_lat, lng: f_lng },
        "Start Location",
        "green",
        {
          title: "Origin",
          
          body: this.from+` Coordinates: Lat: ${f_lat}, Lng: ${f_lng}`,
        }
      );
      //lop through stopsCordinate  


      const endMarker = this.createMarker(
        map,
        { lat: t_lat, lng: t_lng },
        "End Location",
        "red",
        {
          title: "Destination",

          body: this.to + ` Coordinates: Lat: ${t_lat}, Lng: ${t_lng}`,
        }
      );

      const params = {
        origin: f_lat + "," + f_lng,
        destination: t_lat + "," + t_lng,
      };
      const agencyIds = this.selectedAgencies.map(
        (selectedAgency) => selectedAgency.agency_id
      );
      const routesData = await this.calculateAndDisplayRoute({ lat: f_lat, lng: f_lng }, { lat: t_lat, lng: t_lng });
      const body = {
        payment_method: this.selectedPaymentMethod,
        v_class: this.selectedClass,
        travel_date_time: this.travelTime,
        with_trs_agency_ids: agencyIds,
        stopovers: this.stopsCordinate,

        routesData: routesData,


      };
      console.log(body);
      const queryParams = new URLSearchParams(params);


      apirequest(
        "POST",
        `/routeinterpolatecoordinates?${queryParams}`,
        body
      ).then(async (response) => {
        var markers = [];

        const markes_response = response.markers;
        this.toll_cost = response.total_amount.toFixed(2);
        this.agencyData = response.rates;
        this.downloadFormattedText(routesData);



        markers.push(startMarker);
        for (let i = 0; i < markes_response.length; i++) {
          const marker = this.createMarker(
            map,
            markes_response[i].coordinate,
            markes_response[i].name,
            "blue",
            {
              title: response.markers[i] ? response.markers[i].agency_name : "",
              body: `${markes_response[i].name} ${response.rates[i] ? "$" + response.rates[i].amount : ""
                } ${markes_response[i].coordinate}`,
            }
          );

          markers.push(marker);
        }

        for (let i = 0; i < this.stopsCordinate.length; i++) {
          const stop = this.stopsCordinate[i];
          const stopMarker = this.createMarker(
            map,
            { lat: stop.lat, lng: stop.lng },
            `Stop ${i + 1}`,
            "red",
            {
              title: "Stop",
              body: `Stop ${i + 1}`,
            }
          );
          markers.push(stopMarker);
        }

        markers.push(endMarker);


        for (let i = 0; i < response.rates.length; i++) {
          const rate = response.rates[i];
          if (rate.agency_type == 1) {

            const waypoints = [
              new google.maps.LatLng(rate["route_cordinate"]["entry"]),
              new google.maps.LatLng(rate["route_cordinate"]["exit"]),

            ];

            this.drawRoute(map, waypoints, this.travelMode, rate.color, 4);
          }
        }
        this.showTransponderFiltersModel();
      });

      // Get directions and display on the map
    },
    downloadFormattedText(journeyStats) {
      // Prepare the text content for the journey
      let textContent = `Journey from: ${this.origin} to ${this.destination}\n`;
      textContent += `Total Distance: ${this.distnce}\n`;
      textContent += `Total Duration: ${this.travel_time}\n`;
      textContent += `Route Details:\n`;

      // Ensure routeDetails is an array before iterating
      // if (Array.i(journeyStats)) {



      journeyStats.legs.forEach((leg, legIndex) => {

        textContent += `  Leg ${legIndex + 1}:\n`;
        textContent += `    Start Address: ${leg.start_address}\n`;
        textContent += `    End Address: ${leg.end_address}\n`;
        textContent += `    Distance: ${leg.distance.text}\n`;
        textContent += `    Duration: ${leg.duration.text}\n`;
        // textContent += `    Duration in Traffic: ${leg.duration_in_traffic.text}\n`;

        // Steps in the leg

        textContent += `    Steps:\n`;
        if (Array.isArray(leg.steps)) {
          leg.steps.forEach((step, stepIndex) => {

            textContent += `      Step ${stepIndex + 1}:\n`;
            textContent += `        Distance: ${step.distance.text}\n`;
            textContent += `        Duration: ${step.duration.text}\n`;
            // textContent += `        From: (${step.start_location.lat}, ${step.start_location.lng})\n`;
            // textContent += `        To: (${step.end_location.lat}, ${step.end_location.lng})\n`;
            textContent += `        Travel Mode: ${step.travel_mode}\n`;

            // Stripping HTML tags from instructions to make them plain text
            const instruction = step.instructions.replace(/<[^>]*>/g, ''); // Removing all HTML tags
            textContent += `        Directions: ${instruction}\n`;
          });
        }
      });


      // } else {
      //   textContent += "No route details found.\n";
      // }

      // Create a Blob from the text content and trigger the download
      this.journey_details_blob = new Blob([textContent], { type: 'text/plain' });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(journey_details_blob);
      // link.download = 'journey_details.txt';
      // link.click();

    },
    downloadJornyDetail() {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(this.journey_details_blob);
      link.download = 'journey_details.txt';
      link.click();
    },
    createMarker(map, position, label, color, marker_info_objct) {
      var contentString =
        '<div class="info-window">' +
        `<h6>${marker_info_objct.title}</h6>` +
        `<p>${marker_info_objct.body}</p>` +
        "</div>";

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      var marker = new google.maps.Marker({
        position: position,
        map: map,
        label: {
          text: label,
          color: color,
        },
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
          labelOrigin: new google.maps.Point(20, 0),
        },
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });

      return marker;
    },

    async calculateAndDisplayRoute(startMarker, endMarker) {
      // const start = startMarker.getPosition();
      // const end = endMarker.getPosition();
      console.log(startMarker, endMarker);
      const start = new google.maps.LatLng(startMarker.lat, startMarker.lng);
      const end = new google.maps.LatLng(endMarker.lat, endMarker.lng);

      // Simplifying waypoints creation with map() directly if stopsCordinate exists
      const waypoints = this.stopsCordinate.length
        ? this.stopsCordinate.map(coord => new google.maps.LatLng(coord.lat, coord.lng))
        : [];

      // Directions parameters
      const directionParam = {
        origin: start,
        destination: end,
        waypoints: waypoints.length > 0 ? waypoints.map(location => ({ location, stopover: true })) : undefined,
        travelMode: this.travelMode,
        provideRouteAlternatives: true,
        optimizeWaypoints: true,
        drivingOptions: {
          departureTime: new Date(), // Using real-time traffic data
          trafficModel: google.maps.TrafficModel.BEST_GUESS, // Estimate based on current traffic
        },
      };

      var selectedRouteData = []
      await this.directionsService.route(directionParam, (response, status) => {
        console.log(response);

        if (status === "OK") {
          const routes = response.routes;



          this.origin = response.routes[0].legs[0].start_address;
          this.destination = response.routes[0].legs[0].end_address;
          this.distnce = response.routes[0].legs[0].distance.text;
          this.travel_time = response.routes[0].legs[0].duration.text;



          // Check if routes exist
          if (routes.length > 0) {
            selectedRouteData = routes[this.selectedRoutes === -1 ? 0 : this.selectedRoutes];
            // console.log(selectedRouteData,"-------------",this.selectedRoutes);
            const routesData = [];

            // Adjust routes for different selected routes
            if (this.selectedRoutes === -1) {
              // If no route is selected, display all routes
              this.directionsRenderer.setDirections({ ...response, routes: routes.slice(0) });
              this.directionsRenderer2.setDirections({ ...response, routes: routes.slice(1) });

              if (routes.length > 2) {
                this.directionsRenderer3.setDirections({ ...response, routes: routes.slice(2) });
              }

              // Dynamically populate RoutesData based on the number of routes
              for (let i = 0; i < routes.length; i++) {
                routesData.push({ name: `Route ${i + 1}`, id: i });
              }
              this.RoutesData = routesData;
            } else {
              // When a specific route is selected



              if (this.selectedRoutes === 0 || this.selectedRoutes === "0") {
                this.directionsRenderer.setDirections({ ...response, routes: routes.slice(0) });

              } else if (this.selectedRoutes === 1 || this.selectedRoutes === "1") {
                this.directionsRenderer2.setDirections({ ...response, routes: routes.slice(1) });
              } else if (this.selectedRoutes === 2 || this.selectedRoutes === "2") {
                this.directionsRenderer3.setDirections({ ...response, routes: routes.slice(2) });
              }
            }


            // Set the RoutesData dynamically based on the number of available routes

          }

        } else {
          window.alert("Directions request failed due to " + status);
        }

      });
      return selectedRouteData

    },

    drawRoute(
      map,
      waypoints,
      travelMode = this.travelMode,

      strokeColor = "#0000FF",
      strokeWeight = 12
    ) {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: strokeColor,
          strokeWeight: strokeWeight,
        },
      });


      const request = {
        origin: waypoints[0],
        destination: waypoints[waypoints.length - 1],
        waypoints: waypoints
          .slice(1, -1)
          .map((waypoint) => ({ location: waypoint, stopover: true })),
        travelMode: travelMode,
      };

      directionsService.route(request, (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      });
    },

    getAxels() {
      apirequest("get", "/axels").then((response) => {
        this.axlesData = response;
      });
    },
    // getAgencies() {
    //   apirequest("get", "/agency").then((response) => {
    //     this.agencyData = response;
    //   });
    // },
    getPaymentMethod() {
      apirequest("get", "/paymentmethods").then((response) => {
        this.paymentMethodData = response;
      });
    },

    async processFilters() {
      this.getCurrentDateTimeString()
      this.initMap();


    },
  },

  mounted() {
    this.map = new google.maps.Map(document.getElementById("googleMap"), {
      center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
      zoom: 6,
    });
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.map);

    // this.getAgencies();
    this.getAxels();
    this.getPaymentMethod();
  },
};
</script>

<style>
#googleMap {
  height: 100vh;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #84dffa;
  border-radius: 50%;

  margin: 5px;
}
</style>
